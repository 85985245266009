<template>
    <div id="app">
        <a-modal :visible="show" title="角色授权" :styles="styles" @ok="doSubmit" @cancel="_close" centered>
            <a-row :gutter="15">
                <a-col :span="6">
                    <a-switch v-model="isCheck" checked-children="关联选择" un-checked-children="不关联" default-checked />
                </a-col>
            </a-row>
            <a-spin :spinning="loading" tip="Loading...">
                <a-tabs v-model="group" @change="setGroup">
                    <a-tab-pane tab="总后台" key="service-platform">
                        <a-tree :tree-data="menuList" :replaceFields="{ key: 'menuId' }" v-model="formData.roles" ref="tree"
                                :check-directly="true" :checkStrictly="!isCheck" checkable @check="setRoles"></a-tree>
                    </a-tab-pane>
                    <a-tab-pane tab="APP" key="service-appapi">
                        <a-tree :tree-data="menuList" :replaceFields="{ key: 'menuId' }" v-model="formData.roles" ref="tree"
                                :checkStrictly="!isCheck" :check-directly="true" checkable @check="setRoles"></a-tree>
                    </a-tab-pane>
                </a-tabs>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
import request from "@/config/request";
import utils from "@/common/utils";
export default {
    data() {
        return {
            show: false,
            loading: false,
            isCheck: false,
            styles: {
                width: "400px",
                top: "80px"
            },
            group: "service-platform",
            menuList: [],
            formData: {
                roles: [],
                roleId: ""
            }
        };
    },
    methods: {
        _show(roleId) {
            this.group = "service-platform";
            this.formData.roleId = roleId;
            this.formData.roles = [];
            this.getMenuList();
            this.show = true;
        },
        _close() {
            this.group = "service-platform";
            this.formData.roles = [];
            this.show = false;
            this.$emit("close");
        },
        setGroup(group) {
            this.group = group;
            this.formData.roles = [];
            this.getMenuList();
        },
        doSubmit() {
            this.http.post("/platform/role/auth", this.formData).then(ret => {
                if (ret.code == 200) {
                    this.utils.success("授权成功!").then(() => {
                        this._close();
                    });
                }
            });
        },
        getMenuList() {
            this.loading = true;
            request
                .get("/platform/role/menu/" + this.formData.roleId, {
                    group: this.group
                })
                .then(ret => {
                    if (ret.code == 200) {
                        this.menuList = ret.data;
                        this.getAuthorize();
                    }
                })
                .catch(err => {
                    this.loading = false;
                    utils.closeAll();
                });
        },
        getAuthorize() {
            request
                .get("/platform/role/authorize", {
                    platform: this.group,
                    roleId: this.formData.roleId
                })
                .then(ret => {
                    this.formData.roles = ret.data;
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                });
        },
        // setRoles(e) {
        //     this.formData.roles = e.checked;
        // },
        setRoles(checkedKeys, e) {
            console.log(e);
            const { checkedNodes, node } = e;
            const tree = this.$refs.tree;
            console.log(node);
            // Clear existing keys
            this.formData.roles = [];
            console.log(node.$parent);
            this.traverseCheckedNodes(checkedNodes);
            this.formData.roles = [...new Set(this.formData.roles)];
        },

        traverseCheckedNodes(nodes) {
            nodes.forEach(node => {
                this.formData.roles.push(node.key);
                if (node.children) {
                    this.traverseCheckedNodes(node.children);
                }
            });
        },


    }
};
</script>

<style scoped>
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
</style>
